import { useContext, useEffect, useState } from 'react';
import './Connecting.scss'
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';
import Icon from '../Common/Icon';

export const Connecting = () => {
    const { flowStep, gotoNextStep, gotoPrevStep, selectedProvider, progressBarSteps, currentProgressBarStep } = useContext(OnboardingContext);
    const [countdown, setCountDown] = useState(5);

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (countdown === 0) {
            // Countdown has reached zero, perform any action you want here
            console.debug(`End of timer...`)
            return;
        }

        // Set up the interval to decrease the count every second
        const timerId = setInterval(() => {
            setCountDown(countdown - 1);
        }, 1000);

        // Clear the interval when the component unmounts or the count changes
        return () => clearInterval(timerId);
    }, [countdown]);

    return (
        <div className='flowStepContent'>
            <div className='heading'>
                <h1 className='headline'>Just a moment</h1>
                <span className='byline'>We will connect you now to <strong>{selectedProvider.dataholderBrandName}</strong> to securely confirm you're sharing your energy data with us.</span>
            </div>
            <div className='redirectImage'>
                <img src={`/assets/images/paperPlane.png`} className='valuePropImage' alt='Paper aeroplane' />
            </div>
            <div className='nav'>
                <div className='validationError'>
                    <Icon name="error" className="on-disc" />
                    Enbrace will never ask you for your password to share data
                </div>
                <button onClick={gotoNextStep} className='primary next'>Connecting {countdown != 0 ? `in ${countdown}s` : `you now...`}</button>
            </div>
        </div>
    )
};