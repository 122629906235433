import { useContext, useEffect, useState } from 'react';
import { useNavigate, redirect } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { Amplify } from 'aws-amplify';
import { awsconfig } from './AmplifyConfig';
import { getCurrentUser } from 'aws-amplify/auth';

Amplify.configure(awsconfig);

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { authenticatedUser, userProfile, userProfileCheckComplete } = useContext(AuthContext);
    const [hasProfile, setHasProfile] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        console.debug(`From ProtectedRoute: authenticatedUser in context: ${JSON.stringify(authenticatedUser)}`)

        const isAuthenticated = getCurrentUser()
            .then((argument) => {
                console.debug(`From protectedRoute: isAuthenticated: ${JSON.stringify(argument)}`)
                return true;
            })
            .catch((error) => {
                console.debug(`From protectedRoute: Could not find authenticated user: ${JSON.stringify(error)}. Signing in...`)
                //signInWithRedirect({provider: "Google"});
                navigate("/login")
                return false;
            });
/*
        if (!authenticatedUser) {
            navigate("/login")
        }*/

    }, []);

    useEffect(() => {
        console.debug(`User profile changed`);
        if (!authenticatedUser || !userProfileCheckComplete) return;

        if (!userProfile || Object.keys(userProfile).length == 0) {
            console.debug(`No user profile for authenticated user of ${authenticatedUser?.attributes?.name} (${authenticatedUser?.attributes?.email}). Redirecting to onboarding flow.`)
            navigate('/newUser');
            setHasProfile(false);
        } else {
            console.debug(`User profile found: ${JSON.stringify(userProfile)}`)
            setHasProfile(true);
        }
    }, [userProfileCheckComplete])

    return <Component {...rest} />;
 
    if (userProfileCheckComplete && hasProfile) {
        return <Component {...rest} />;
    } else {
        return "Checking your Enbrace profile..."
    }

};


export default ProtectedRoute;
