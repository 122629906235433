import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import * as amplitude from "@amplitude/analytics-browser";
import { CommonDataProvider } from '../utils/CommonDataContext';

const Blog = () => {
    const { authenticatedUser } = useContext(AuthContext);

    useEffect(() => {
        amplitude.track(`Blog page rendered`);
    }, [])

    if (authenticatedUser) {
        return (
            <Site>
                <CommonDataProvider>
                    <PageHeaderBar />
                    <h1>Enbrace blog</h1>
                    <p>We will publish a blog to keep everyone informed about the latest in household energy and ways to save.</p>
                    <p>If you would like to see this built out, complete the <a href='/feedback'>feedback form</a> so we know there is interest!</p>
                    <PageFooter />
                </CommonDataProvider>
            </Site>
        );
    } else {
        return 'No user';
    }

};

export default Blog;
