import '@rmwc/dialog/styles';
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle } from '@rmwc/dialog';
import { useHint } from '../../utils/HintContext';
import './Hints.scss'
import Icon from './Icon';
import Button from './Button';

export const HintModal = () => {
    const { hintTopic, setHintTopic } = useHint();

    const resetHint = (detail) => {
        console.debug(`Event details: ${JSON.stringify(detail)}`)
        if (detail.action.startsWith('https://')) {
            console.debug(`Open new tab to ${detail.action}`);
            window.open(detail.action, '_blank');
        }
        setHintTopic(null);
    };

    return <Dialog open={hintTopic || false} onClose={(event) => { resetHint(event.detail); }} className='hintDialog'>
        <DialogTitle className='hintTitle'>{hints[hintTopic]?.icon && <Icon name={hints[hintTopic]?.icon} className="hintIcon" />}{hints[hintTopic]?.title}</DialogTitle>
        <DialogContent className='hintContent'>{hints[hintTopic]?.content}</DialogContent>
        <DialogActions className='hintActions'>
            {
                hints[hintTopic]?.actions.map((action, index) => {
                    return <DialogButton ripple={false} className="hintButton" action={action.action} isDefaulAction={action.defaultAction} key={index}>{action.label}</DialogButton>

                })
            }
        </DialogActions>
    </Dialog>
}

export const HintButton = ({ topic }) => {
    const { setHintTopic } = useHint();

    const handleClick = (event) => {
        console.debug(`Hint button was clicked: ${topic}`)
        setHintTopic(topic);
    };

    return (
        //<a href="#" className='infoButton' topic={topic} onClick={(event) => { handleClick(event); }}>Info</a>
        <Button type="info" icon="info" clickHandler={(event) => { handleClick(event); }} />
    );
};


const hints = {
    yourCosts: {
        title: "Your costs",
        content: "This is what your next 12 months is expected to cost you on your current plan, assuming the same usage as the previous 12 months. This calculation includes general usage, controlled load usage, solar feed-in, daily supply fees, membership fees, concessions & GST. It is judged as either low, medium or high as compared to what your usage will cost you on all other plans that are available to you.",
        actions: [
            {
                label: "OK",
                action: "close",
                defaultAction: true
            }
        ]
    },
    customerRating: {
        title: "Customer rating",
        content: "The customer rating is an indicator as to how customers think about the retailer. This score is an average of current values from two major review sites.",
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    ecoRating: {
        title: "Eco rating",
        content: "The eco rating is an indication of how environmentally conscious the retailer is. It comes from the Greenpeace Green Electricity Guide, which analyses all electricity retailers on six main criteria; provision of clean renewable energy, ending dirty coal use by 2030, halting fossil fuel expansion, support for renewable energy, transparency in marketing, and avoiding pollution and evnironmental harm.",
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },    
    expensivePlans: {
        title: "Expensive plans",
        content: "We've calculated what your usage for the past 12 months would have cost you on all other plans available to at your location. These ones are the most expensive plans available to you. We are not funded at all by any energy retailers, so this assessment is 100% independent and completed in the interest of getting you a better deal.",
        actions: [
            {
                label: "OK",
                action: "close",
                defaultAction: true
            }
        ]
    },
    betterPlans: {
        title: "Better plans",
        content: "We've calculated what your usage for the past 12 months would have cost you on all other plans available to at your location. These are the least expensive ones and show what their difference is from your current plan. We are not funded at all by any energy retailers, so this assessment is 100% independent and completed in the interest of getting you a better deal.",
        actions: [
            {
                label: "OK",
                action: "close",
                defaultAction: true
            }
        ]
    },
    betterPlanCalc: {
        title: "How we calculate plan costs",
        content: "We source all the plan details direct from the Australian Energy Regulators (makers of Energy Made Easy), without bias or favour toward any particular provider. We get the details of your current plan direct from your provider. For each plan available at your address and your meter type, including your current plan, we calculate what that plan would have cost you based on your usage history hour by hour over the course of the past 12 months. This considers date varying plans, time varying plans, usage costs, supply charges, export tariffs, other fees and charges (e.g. memberships). Where there is not 12 months of usage history, the history we do have is averaged over the 12 months. We then order them all from least to most expensive.",
        actions: [
            {
                label: "OK",
                action: "close",
                defaultAction: true
            }
        ]
    },
    consentPersonalDeets: {
        icon: "error",
        title: "Name, occupation, contact details",
        content: <>
            <p>This allows access to detailed personal information like your residential address.</p>
            <ul><p><strong>Enbrace</strong> will have access to the following data, if available:</p>
                <li>Name</li>
                <li>Occupation</li>
                <li>Phone</li>
                <li>Email address</li>
                <li>Mail address</li>
                <li>Residential address</li>
            </ul>
        </>,
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    consentAccountDeets: {
        icon: "location",
        title: "Account and plan details",
        content: <>
            <p>This allows access to your account detail information.</p>
            <ul><p><strong>Enbrace</strong> will have access to the following data, if available:</p>
                <li>Account and plan information</li>
                <li>Account type</li>
                <li>Fees, features, rates, and discounts</li>
                <li>Additional account users</li>
            </ul>
        </>,
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    consentConcessions: {
        icon: "error",
        title: "Concessions and assistance",
        content: <>
            <p>This allows access to your account concessions.</p>
            <ul><p><strong>Enbrace</strong> will have access to the following data, if available:</p>
                <li>Concession type</li>
                <li>Concession information</li>
            </ul>
        </>,
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    consentPaymentDeets: {
        icon: "error",
        title: "Payment preferences",
        content: <>
            <p>This allows access to your account payment schedules.</p>
            <ul><p><strong>Enbrace</strong> will have access to the following data, if available:</p>
                <li>Payment and billing frequency</li>
                <li>Any scheduled payment details</li>
            </ul>
        </>,
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    consentBillingHistory: {
        icon: "error",
        title: "Billing payments and history",
        content: <>
            <p>This allows access to your bills.</p>
            <ul><p><strong>Enbrace</strong> will have access to the following data, if available:</p>
                <li>Account balance</li>
                <li>Payment method</li>
                <li>Payment status</li>
                <li>Charges, discounts, credits</li>
                <li>Billing date</li>
                <li>Usage for billing period</li>
                <li>Payment date</li>
                <li>Invoice number</li>
            </ul>
        </>,
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    consentUsageHistory: {
        icon: "error",
        title: "Electricity usage",
        content: <>
            <p>This allows access to your electricity usage history.</p>
            <ul><p><strong>Enbrace</strong> will have access to the following data, if available:</p>
                <li>Usage</li>
                <li>Meter details</li>
            </ul>
        </>,
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    consentMeterDeets: {
        icon: "error",
        title: "Electricity connection and meter",
        content: <>
            <p>This allows access to service point detail information.</p>
            <ul><p><strong>Enbrace</strong> will have access to the following data, if available:</p>
                <li>National Meter Identifier (NMI)</li>
                <li>Customer type</li>
                <li>Connection point details</li>
                <li>Supply address</li>
                <li>Meter details</li>
                <li>Associated service providers</li>
            </ul>
        </>,
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    consentDER: {
        icon: "error",
        title: "Energy generation and storage",
        content: <>
            <p>This allows access to your electricity distributed energy resources (DER).</p>
            <ul><p><strong>Enbrace</strong> will have access to the following data, if available:</p>
                <li>Generation information</li>
                <li>Generation or storage device type</li>
                <li>Device characteristics</li>
                <li>Devices that can operate without the grid</li>
                <li>Energy conversion information</li>
            </ul>
        </>,
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    cdrConsent: {
        icon: "lockShield",
        title: "Consumer Data Right (CDR)",
        content: <>
            <p>The Consumer Data Right allows consumers to safely share the data that businesses hold about them. It can help consumers to compare products and services to find offers that best match their needs.</p>
            <img className="cdrLogo" src="/assets/images/CDR_Primary_RGB_Colour.svg" />
        </>,
        actions: [
            {
                label: "More about CDR",
                action: 'https://www.cdr.gov.au/',
                defaultAction: false
            },
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    yourPlanCosts: {
        title: "Cost of your current plan",
        content: "This is how much your usage patterns for the past 12 months would have cost you on your current plan. It is represented by the red bar in the chart.",
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    thisPlanCosts: {
        title: "Cost of the selected plan",
        content: "This is how much your usage patterns for the past 12 months would have cost you on this selected plan. It is represented by the what bar in the chart.",
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    comparedSavings: {
        title: "Difference between the two plans",
        content: "This is the difference in cost between your current plan and this selected plan of your last 12 months of usage. Assuming similar usage patterns for the next 12 months, this is the estimated difference in annual cost if you were to switch to the selected plan.",
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    howWeComparePlans: {
        title: "How we compare this plan",
        content: "We get the difference between the calculated cost of your current plan and the calculated cost of the selected plan. These calculations take into account your hour-by-hour usage patterns, daily charges, any export tariffs, other fees and charges to find an accurate and personalised cost for your house. The difference becomes the estimated annual savings, assuming similar usage patterns next year to last.",
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },
    greenPower: {
        title: "GreenPower",
        content: "Electricity retailers can commit to offsetting a percentage of carbon emissions related to the generation of the relectricity that you use. This is known as GreenPower. Some plans will include a percentage of GreenPower intrinsically. We call this complementary GreenPower, because it is not an additional cost to you. Some plans will also include the option to choose various percentages of GreenPower for an additional fee. We call this optional GreenPower.",
        actions: [
            {
                label: "Close",
                action: "close",
                defaultAction: true
            }
        ]
    },

}